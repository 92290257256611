/**
 * @Author: siyukok
 * @Date: 2022/3/3 10:24 AM
 * @Version 1.0
 */
export default {
  login: {
    login: '退出登录'
  },
  route: {
    Project_Center: '项目中心',
    WSSO_Project: 'WSSO项目',
    Carbon_Credits_Project: '碳汇项目',
    RECs_Project: '绿证项目'
  },
  WSSO_Project: {
    Search: '搜索',
    Project_Filter: '项目标准筛选',
    Clear_Search: '重置',
    Sync_Project: '同步项目',
    Project_Homepage: '项目主页',
    Project_ID: '项目编号',
    Project_Name: '项目名称',
    Project_Owner: '项目业主',
    Project_Assets_Amount: '项目总资产',
    Input_Project_ID_Please: '请输入项目编号',
    Cancel: '取消',
    Submit: '保存',
    Success: '成功',
    Total_Carbon_Credits: '总资产',
    Project_Location: '项目区域',
    Project_Address: '项目地址',
    Installed_Capacity: '项目装机容量',
    Generation_Type: '项目发电类型',
    Coordinate_Inflection_Point: '项目拐点坐标',
    Methodology: '项目方法学',
    methodology_WSSO: '此方法学已经过WSSO官方认证',
    Project_PDD: '项目设计文件',
    Project_PDD_On_Chain_Hash: '项目设计文件上链哈希',
    Project_PVR: '项目审定报告',
    Project_PVR_On_Chain_Hash: '项目审定报告上链哈希',
    Sync_Carbon_Credits: '同步新代理碳汇',
    ID: '碳汇编号',
    Amount: '碳汇数量',
    Integrity_Score: '完整性评分',
    MR: '核证报告',
    MR_On_Chain_Hash: '核证报告上链哈希',
    Certification: '碳汇证书',
    Certification_On_Chain_Hash: '碳汇证书上链哈希',
    Serial_Number: '碳汇序列号',
    State: '状态',
    Operate: '操作',
    Input_Carbon_Credits_ID_Please: '请输入碳汇编号',
    RWA_Tokenization: '铸造RWA',
    Collection_Information: 'Collection属性',
    RWA_Information: 'RWA属性',
    Carbon_Credits_ID: '碳汇编号',
    Carbon_Dioxide_Equivalent: '碳汇数量',
    Carbon_Credits_Integrity_Score: '完整性评分',
    Carbon_Credits_Serial_Number: '碳汇序列号',
    Carbon_Credits_MR: '碳汇核证报告',
    Carbon_Credits_Certification: '碳汇证书',
    Carbon_Credits_MR_On_Chain_Hash: '碳汇核证报告上链哈希',
    Carbon_Credits_Certification_On_Chain_Hash: '碳汇证书上链哈希',
    Agency_Date: '代理日期',
    RWA_Tokenization_InCapture: '请使用InCapure Vault移动端钱包扫描二维码完成铸造RWA操作。',
    RWA_Authentication: '资产确权',
    RWA_Authenticate_process: '资产确权，应经过会计师事务所、律师事务所、持牌金融机构、资产托管机构、业主等业务主体在身份链上进行多签确认，此POC对此环节进行简化。',
    To_Be_Authenticated_by_Owner: '待业主确权',
    Authenticated_by_Owner: '业主已确权',
    Owner_Authenticate: '业主确权',
    To_Be_Authenticated_by_RWA_Development_Organization: '待资产开发机构确权',
    Authenticated_by_RWA_Development_Organization: '资产开发机构已确权',
    RWA_Development_Organization_Authenticate: '资产开发机构确权',
    To_Be_Authenticated_by_Accounting_Firm: '待会计师事务所确权',
    Authenticated_by_Accounting_Firm: '会计师事务所已确权',
    Accounting_Firm_Authenticate: '会计师事务所确权',
    To_Be_Authenticated_by_Low_Firm: '待律师事务所确权',
    Authenticated_by_Low_Firm: '律师事务所已确权',
    Low_Firm_Authenticate: '律师事务所确权',
    To_Be_Authenticated_by_Licensed_Financial_Organization: '待持牌金融机构确权',
    Authenticated_by_Licensed_Financial_Organization: '持牌金融机构已确权',
    Licensed_Financial_Organization_Authenticate: '持牌金融机构确权',
    RWA_Authentication_InCapture_Vault: '请使用InCapure Vault移动端钱包扫描二维码完成资产确权操作。',
    Authentication_Hash: '确权哈希',
    RWA_Tokenized: 'RWA已铸造',
    RWA_To_Be_Authenticated: '资产待确权',
    RWA_Authenticated: '资产已确权'
  },
  Carbon_Credits_Project: {
    Register_Project: '登记新项目',
    Project_Homepage: '项目主页',
    Project_Type: '项目类型',
    Project_Standard: '项目标准',
    Carbon_Credits_Project_Registration: '绿色资产新项目登记',
    Input_Project_Owner_DID_Please: '请输入项目业主DID',
    Input_Project_Standard_Please: '请选择项目标准',
    Input_Project_Homepage_Please: '请输入项目主页地址',
    Input_Project_Name_Please: '请输入项目名称',
    Input_Project_Location_Please: '请输入项目地区',
    Input_VCS_Type_Please: '请输入VCS类型',
    Input_VCS_Methodology_Please: '请输入VCS方法论',
    Input_VCS_Validator_Please: '请输入VCS验证器',
    Select_Registration_Date_Please: '请选择项目注册日期',
    Select_Certification_Period_Please: '请选择项目认证期限',
    Certification_Period_Start_Date: '请选择开始日期',
    Certification_Period_End_Date: '请选择结束日期',
    VCS_Type: 'VCS类型',
    VCS_Methodology: 'VCS方法论',
    VCS_Validator: 'VCS验证器',
    Registration_Date: '项目注册日期',
    Certification_Period: '项目认证期限',
    ETH_Address: 'ETH地址',
    Input_ETH_Address_Please: '请输入ETH地址',
    Project_Area: '项目面积',
    Input_Project_Area_Please: '请输入项目面积',
    Register_VCUs: '登记新代理VCUs',
    Agency_Date: 'VCUs代理日期',
    Amount: 'VCUs数量',
    Serial_Number: 'VCUs代理序列号',
    State: '状态',
    Assets_Measurement: '资产计量',
    Select_Register_VCUs_Please: '请选择VCUs代理日期',
    Input_Amount_Please: '请输入VCUs数量',
    Input_Serial_Number_Please: '请输入VCUs代理序列号',
    Project_Country: '项目所在国家',
    Input_Project_Country_Please: '请输入设备所在国家',
    Commissioning_Date: '项目投产日期',
    Select_Commissioning_Date_Please: '请选择设备投产日期',
    Expiry_Date: '项目到期日期',
    Select_Expiry_Date_Please: '请选择项目到期日期',
    Technology: '项目技术',
    Input_Project_Technology_Please: '请输入设备技术',
    Primary_Fuel: '项目燃料',
    Input_Project_Primary_Fuel_Please: '请输入设备燃料',
    Capacity: '项目装机容量',
    Input_Project_Capacity_Please: '请输入项目装机容量',
    Register_I_RECs: '登记新代理I-RECs',
    Agency_Date_I_RECs: 'I-RECs代理日期',
    Amount_I_RECs: 'I-RECs数量',
    Serial_Number_I_RECs: 'I-RECs代理序列号',
    Select_Agency_Date_Please: '请选择I-RECs代理日期',
    Input_IRECs_Amount_Please: '请输入I-RECs数量',
    Input_IRECs_Serial_Number_Please: '请输入I-RECs代理序列号'
  }
}
